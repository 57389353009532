:root {
  --primary: #002D44;
  --primary: teal;
  --primary-50: #30526C;
  --light-primary: #E7F0F5;
  --light-primary-20:  #F5F9FF;
  --otherSide-bg: #E7F0F5;
  --light-primary-10: #85A6C3;;
  --medium-light-primary: #499ECD;
  --secondary: #FA544B;
  --amber: #E86825;
  --amber-light: #e8692533;
  --red-light: rgba(255, 0, 0, 0.14);
  --secondary-light: #FFE0E1;
  --light-white: #ffffffab;
  --grey: #c3c3c3;
  --light-grey: #D9D9D9;
  --x-light-grey: #f4f4f4;
  --light-grey-transparent: #d9d9d963;
  --xx-light-grey: #f8f9fa;
  --medium-grey: #b6b6b6;
  --dark-grey: #959595;
  --x-dark-grey: #616161;
  --xx-dark-grey: #50505E;
  --transparent-white: #ffffff6b;
  --green: #1BA769;
  --green-light: #91e8c0;
  --normal-radius: .6rem;
  --medium-radius: .6rem;
  --very-cornered-radius: 1.5rem;
  --small-radius: .27rem;
  --full-radius: 100%;
  --shadow-sm: 0px 0px 4px 0px rgb(58 57 76 / 10%);
  --card-shadow: 1px 1px 5px 0px rgb(58 57 76 / 7%);
  // --shadow-sm: 0px 2px 4px rgba(58, 57, 76, 0.03);
  --shadow-md: 0px 0px 13px 0px #3A394C0F;
  // --shadow-md: 0px 4px 6px 0px #3A394C0F;
}

@font-face {
  font-family: "medium";
  src: url("assets/fonts/WorkSans-Medium.ttf");
}

@font-face {
  font-family: "regular";
  src: url("assets/fonts/WorkSans-Regular.ttf");
}

@font-face {
  font-family: "light";
  src: url("assets/fonts/WorkSans-Light.ttf");
}

@font-face {
  font-family: "bold";
  src: url("assets/fonts/WorkSans-Bold.ttf");
}

@font-face {
  font-family: "semi-bold";
  src: url("assets/fonts/WorkSans-SemiBold.ttf");
}

body {
  margin: 0;
  color: black;
  font-family: regular;
}

html,
body {
    height: 100%;
    width: 100%; 
}