.footerContainer {
  width: 100%;
  display: flex;
  padding: 0rem 10rem;
  margin-top: 2rem;
  .innerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--light-grey);
    padding: 2rem 0rem;
    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      .iconItem {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        border-radius: var(--small-radius);
        border: 2px solid var(--x-light-grey);
        cursor: pointer;
        // box-shadow: var(--shadow-sm);
        padding: .25rem;
        margin-right: 1rem;
        i {
          font-size: 1.25rem;
        }
      }
      .iconItem:last-child {
        margin-right: 0rem;
      }
    }

  }
}

@media only screen and (max-width: 699px) {
  .footerContainer {
    padding: 0rem 1rem;
    .innerContainer {
      .iconContainer {
        .iconItem {
          margin-right: 1.5rem;
          i {
            font-size: 1.5rem;
          }
        }
      }
  
    }
  }
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .footerContainer {
    padding: 0rem 3rem;
    .innerContainer {
      .iconContainer {
        .iconItem {
          margin-right: 2rem;
          i {
            font-size: 2rem;
          }
        }
      }
  
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1336px) {
  .footerContainer {
    padding: 0rem 4rem;
  }
}